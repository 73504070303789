import React from "react"
import Seo from "utils/seo"
import Layout from "components/Layout"
import Heading from 'src/utils/Heading';
import ButtonBig from 'src/utils/ButtonBig';

export default function Page404({ location }) {
  return (
    <>
      <Seo pageName={"404"} pageMeta={{description: "page not found"}} />
      <Layout location={location}>
        <div className={`h-screen bg-white flex flex-col justify-center items-center`}>
              <div className="max-w-[813px] mx-auto px-6">
                  <div className="lg:mr-[150px]">
                        <Heading size="h2" className={`!leading-[0.9] text-red`}>
                            OOPS, THE PAGE YOU’RE LOOKING FOR CAN’T BE FOUND.
                        </Heading>
                      <div className={`!leading-snug text-black font-light`}>
                          Please forgive us - we're so focused on looking after our clients that we have missed this error. Please return to the homepage.
                      </div>
                  </div>
                  <div className="flex mt-5 lg:mt-0">
                      <ButtonBig className="ml-auto" to='/' children='Back to homepage' colour="red" transparent={false} />
                  </div>          
              </div>
          </div>
      </Layout>
    </>
  )
}
